function Footer() {
    return (
        <div className="Footer">
            <img src={require('./assets/OrcaLogo.png')} alt='logo'/>
            <p>&copy; 2024 ORCA. All rights reserved.</p>
            <p>
                This website and its content are the copyright of ORCA &copy; 2024.
                Unauthorized copying, distribution, reproduction, or modification of any content is strictly prohibited.
            </p>
        </div>)
}
export default Footer