import './App.css';
function TelegramBtn(){
    const openTelegram = () => {
        window.open('https://t.me/orcaonsolana', '_blank');
      };
    return(
        <div className='SocialMediaBtn Telegramicon rotate5deg' onClick={openTelegram}>
        </div>
    )
}
export default TelegramBtn