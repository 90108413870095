import './App.css';
function XBtn(){
    const openX = () => {
        window.open('https://X.com/orcaonsolana', '_blank');
      };
    return(
        <div className='SocialMediaBtn Xicon rotate5deg' onClick={openX}>
        </div>
    )
}
export default XBtn