import './App.css';
import XBtn from './XBtn';
import TelegramBtn from './TelegramBtn';
import Footer from './Footer';
import ExchangeIcon from './ExchangeIcon';
function App() {
  const openRaydium = () => {
    window.open('https://raydium.io/swap/?inputMint=sol&outputMint=nA4c2vt8nGHrD9mGLVt8G3ch7scnCQ6VCxtV6jopump', '_blank');
  };

  var tokenAdress = "nA4c2vt8nGHrD9mGLVt8G3ch7scnCQ6VCxtV6jopump"
  return (
    <div className="App">
      <div className='HeaderContainer'>
        <img src={require('./assets/OrcaLogo.png')} className='Logo' alt='Orca Logo' />
        <div className='HeaderContents'>
          <div className="HeaderTextBOX">
            <h1><span className='pinkHighlight'>Orca</span> - the whale of Solana!</h1>
            <h2>Our mission is to help endangered species and clean the ocean, one step at a time!</h2>
            <div className='BuyBtn rotate5deg' onClick={openRaydium}>Buy Orca</div>
            <div className='SMIconsContainer'>
              <XBtn />
              <TelegramBtn />
            </div>
          </div>
          <img src={require('./assets/OrcaArt1.png')} className='HeaderImg' alt='Orca Art1' />
        </div>
      </div>
      <div className="ticker-container">
        <div className="ticker">
          <h1 className="ticker-text">
            $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA
            $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA
            $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA
            $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA
            $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA
            $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA
            $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA
            $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA
            $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA $ORCA
          </h1>
        </div>
      </div>
      <div className='ExchangesContainer'>
        <h1 className='ContainerHeaders' style={{ color: "#00EEFF" }}>Exchanges</h1>
        <h2>Orca is soon available for trading on major exchanges.</h2>
        <div className='ExchangeIconContainer'>
          <ExchangeIcon />
          <ExchangeIcon />
          <ExchangeIcon />
          <ExchangeIcon />
          <ExchangeIcon />
          <ExchangeIcon />
          <ExchangeIcon />
          <ExchangeIcon />
        </div>
        <h3>More exchange listings are on the way.</h3>
      </div>
      <div className='UtilitiesContainer'>
        <h1 className='ContainerHeaders' style={{ color: "#1f1f29" }}>Orca Utilities</h1>
        <h2>Seemlessly integrated & Community powered</h2>
        <div className='UtilitiesData'>
          <div className='UtilitiesDataItem'>
            <p className='UtilitiesDataItemTitle'>Expanding ecosystem</p>
            <p className='UtitlitiesDataItemSUB'>Orca's ecosystem continues to grow with partnerships across cexs, dexs, and even travel platforms, enabling users to leverage $ORCA for trading, asset management, and global travel bookings.</p>
          </div>
          <div className='UtilitiesDataItem'>
            <p className='UtilitiesDataItemTitle'>Community support</p>
            <p className='UtitlitiesDataItemSUB'>Orca thrives on strong community backing, with continuous support from both crypto enthusiasts and leading platforms, driving its adoption and utility in the wider crypto space.</p>
          </div>
          <div className='UtilitiesDataItem'>
            <p className='UtilitiesDataItemTitle'>Robust integrations</p>
            <p className='UtitlitiesDataItemSUB'>Orca has established integrations with top exchanges, wallets, and services in the crypto space, ensuring seamless accessibility and utility for users across various platforms.</p>
          </div>
        </div>
      </div>
      <div className='GuideContainer'>
        <h1 className='ContainerHeaders' style={{ color: "#00EEFF" }}>How to Buy</h1>
        <h2 style={{ color: "#fff" }}>Follow these easy steps and become an ORCA holder</h2>
        <div className='GuideData'>
          <div className='GuideDataItem'>
            <p className='GuideDataItemTITLE'><span style={{color:"#00EEFF"}}>1.</span>Create a wallet with Phantom</p>
            <p className='GuideDataItemSUB'>Visit <a href='https://phantom.app/'target="_blank" rel="noreferrer" style={{color:"#00EEFF"}}>phantom.App</a> and follow the simple steps to create a new account with the phantom app or browser extension.</p>
          </div>
          <div className='GuideDataItem'>
            <p className='GuideDataItemTITLE'><span style={{color:"#00EEFF"}}>2.</span>Get some $SOL</p>
            <p className='GuideDataItemSUB'>Tap the BUY button in the app to purchase Solana, or deposit $SOL to your Phantom wallet from the crypto exchange of your choice.</p>
          </div>
          <div className='GuideDataItem'>
            <p className='GuideDataItemTITLE'><span style={{color:"#00EEFF"}}>3.</span>Swap $SOL for $ORCA</p>
            <p className='GuideDataItemSUB'>Tap the SWAP icon in your Phantom wallet and paste the $ORCA token address. Swap your $SOL for $ORCA.</p>
          </div>
          <div className='GuideDataItem'>
            <p className='GuideDataItemTITLE'><span style={{color:"#00EEFF"}}>4.</span>You are now an $ORCA holder!</p>
            <p className='GuideDataItemSUB'>Welcome to the $ORCA Squad!</p>
          </div>
        </div>
      </div>
      <div className='TokenomicsContainer'>
        <h1 className='ContainerHeaders' style={{ color: "#1f1f29" }}>Tokenomics</h1>
        <h2>Orca made a billion tokens but he's a <span style={{ color: "#E55B8C" }}>GREEDY MANIAC</span> and wants to keep them all to himself.</h2>
        <div className='TokenomicsContent'>
          <div className='TokenomicsOrcaArt'></div>
          <div className='TokenomicsData'>
            <div className='TokenomicsDataItemO' />
            <div className='TokenomicsDataItem'>
              <h1>Total supply</h1>
              <h2>1,000,000,000 $ORCA</h2>
            </div>
            <div className='TokenomicsDataItem'>
              <h1>Token address</h1>
              <h2>{tokenAdress}</h2>
            </div>
          </div>
        </div>
        <div className='BuyBtn' style={{ margin: "0" }} onClick={openRaydium}>Buy Orca</div>

      </div>
      <div className='AnimationContainer' />
      <div className='Seperator1'>
        <img src={require('./assets/OrcaArt2.png')} className='SeperatorIMG' alt='Orca Art2' />
        <img src={require('./assets/OrcaLogo.png')} className='Logo' alt='Orca Logo' />
        <img src={require('./assets/OrcaArt2.png')} className='SeperatorIMG reverseIMG' alt='Orca Art2' />
      </div>
      <div className='SocialsContainer'>
        <h1 className='ContainerHeaders'>Socials</h1>
        <div className='SMIconsContainer2'>
          <XBtn />
          <TelegramBtn />
        </div>
        <img src={require('./assets/OrcaArt3.png')} className='HeaderImg margintop1' alt='Orca Art 3' />
      </div>
      <Footer />

    </div>
  );
}

export default App;
